
import fingerprint from '~/mixins/fingerprint'
import login from '~/mixins/common/pages/login'
import ReCaptcha from '~/mixins/recaptcha'
import Login from '~/mixins/pages/login/login'
import checkSuspendWebsite from '~/mixins/pages/login/checkSuspendWebsite'

export default {
    name: 'Theme3DesktopLogin',
    mixins: [fingerprint, ReCaptcha, Login, login, checkSuspendWebsite],
    middleware: 'auth',
    data() {
        return {
            isForgotPassword: false,
        }
    },
    async mounted() {
        const username = this.$cookie.get('un')
        const phoneNumber = this.$cookie.get('pn')
        const loginOption = this.$cookie.get('login_option')
        if (loginOption && ['phone', 'username'].includes(loginOption))
            this.loginType = loginOption

        if (username) this.form.player_id = username
        if (phoneNumber) this.form.phone = phoneNumber

        this.form.reg_fingerprint = await this.visitorId()
        const countryCode = this.$cookie?.get('country_code')

        if (this.isInternational) {
            this.country =
                countryCode === 'GO'
                    ? this?.$cookie?.get('global_reg')
                    : countryCode
        } else {
            this.country = 'LA'
        }
    },
}
